<template>
  <div class="main-frame">
    <div class="category-title">
      <h2>검색: "{{ searchText }}"</h2>
    </div>
    <div class="center-frame">
      <div class="container">
        <div class="alignment-line">
          <p>Items {{ itemList.length }}</p>
          <div class="alignment" @click="alignment">
            최신순
            <img v-if="isLatest" src="@/assets/images/icon/icon_gray_down.png">
            <img v-else src="@/assets/images/icon/icon_gray_up.png">
          </div>
        </div>
        <div class="items-container">
          <div class="card-box" v-for="(item,i) in itemList" :key="i"
               @click="moveItemPage(item)">
            <CardView :cardData="item"/>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <h2>라마켓에서 리소스를 공유하고<br/>
        또다른 수익을 창출 해보세요!</h2>
      <div class="banner-img">
        <img src="@/assets/images/img_theme_banner.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CardView from "@/components/CardView.vue";
import {firestore} from "@/firebase/firebaseConfig";

export default {
  name: 'Home',
  components: {
    CardView,
  },
  data() {
    return {
      isLatest: true,
      searchText: this.$route.params.text,
      categoryTitle: '',
      subCategoryTitle: '',
      itemList: [],
    }
  },
  watch: {
    $route() {
      this.searchText = this.$route.params.text
      this.getItem()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const self = this;
      await self.getItem()
    },
    getItem() {
      const self = this;

      self.itemList.splice(0)
      firestore.collection('item')
          .orderBy('lastDate', 'desc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const _data = doc.data()
              _data['uid'] = doc.id
              if (_data.title.includes(self.searchText))
                self.itemList.push(_data);
            })
          })
    },
    moveItemPage(value) {
      const self = this;
      self.$router.push({name: 'ItemDetail', params: {type: value.category, uid: value.uid}})
    },
    alignment() {
      const self = this;
      self.isLatest = !self.isLatest

      if (self.isLatest)
        self.itemList.sort((a, b) => b.lastDate - a.lastDate);
      else
        self.itemList.sort((a, b) => a.lastDate - b.lastDate);
    },
  },
}
</script>
<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .category-title {
  text-align: start;
  margin-bottom: 20px;
}

.main-frame .category-title div {
  color: #71717A;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.main-frame .category-title h2 {
  color: #71717A;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.main-frame .center-frame {
  display: flex;
}

.center-frame .container .alignment-line {
  display: flex;
  justify-content: space-between;
  height: 52px;
  padding: 0 16px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  margin-bottom: 30px;
  color: #A1A1AA;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

.center-frame .container .alignment-line .alignment {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.center-frame .container .alignment-line .alignment img {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}

.center-frame .container .items-container {
  padding: 0 16px;
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}


.banner {
  height: 286px;
  background: #FBF4EB;
  padding: 0 130px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-radius: 20px;
}

.banner h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  margin: auto 0;
}

.banner .banner-img {
  width: 420px;
  height: 270px;
}

.banner .banner-img img {
  width: 100%;
  height: 100%;
}
</style>